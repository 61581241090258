@use "../abstracts/mixins" as *;

.description {
  background-color: var(--clr-medium-light-grey);
  color: var(--clr-white);
  padding: 1rem;
  grid-column: 2;
  min-height: 150px;
}

@include mq(medium) {
  .description {
    padding: 1.5rem 2.25rem 3rem;
  }
}
