.zip-search {
  background-color: var(--clr-primary);
  color: var(--clr-white);
  padding: 1rem 1.5rem;

  &__title {
    font-size: var(--fs-400);
    margin-block-start: 0;
    margin-block-end: 1.25rem;
    color: var(--clr-white);
  }

  &__form {
    display: grid;
    gap: 0.75rem;
    justify-items: start;
  }

  &__input {
    justify-self: stretch;
    padding: 0.25rem 0.5rem;
    border: none;

    &::placeholder {
      font-size: var(--fs-300);
    }
  }
}
