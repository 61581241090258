.skip-links {
  isolation: isolate;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 1rem;

  &__link {
    translate: 0 -100%;
    position: absolute;
    transition: translate 300ms ease-in-out;
    display: inline-block;
    background-color: var(--clr-white);
    padding: 0.5rem 1rem;
    width: max-content;

    &:focus {
      translate: 0;
    }
  }
}
