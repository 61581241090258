@use "../abstracts/mixins" as *;

@include mq(medium, max) {
  .primary-navigation {
    position: absolute;
    inset: 4.5rem 0 0;
    overscroll-behavior: contain;
    transform: translateY(-200%);
    transition: transform 200ms ease-in-out;
    background-color: white;
  }
}

.primary-navigation {
  justify-self: start;
  grid-area: primary-navigation;
}
