@use "../abstracts/mixins" as *;

.footer {
  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  &__link {
    display: inline-block;
    padding-block: 1.125rem;
    text-transform: uppercase;
    font-size: 0.75rem;
    color: var(--clr-grey);
    background: none;
    border: none;
    cursor: pointer;
  }
}

@include mq(small) {
  .footer {
    padding-block: 2rem;

    &__list {
      flex-direction: row;
      gap: 2rem;
    }
  }
}
