@use "../abstracts/mixins" as *;

@include mq(medium, max) {
  .secondary-navigation {
    display: none;
  }
}

@include mq(medium) {
  .secondary-navigation {
    grid-area: secondary-navigation;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.75rem;

    &__login {
      display: inline-flex;
      align-items: center;
      gap: 0.25rem;
      padding: 0 0.5rem;
      background-color: var(--clr-primary);
      color: var(--clr-white);
      font-weight: 700;
      font-size: var(--fs-300);

      &:hover {
        color: var(--clr-white);
      }
    }

    &__list {
      list-style: none;
      display: flex;
      gap: 1rem;
    }

    &__link {
      color: var(--clr-dark-grey);
      font-weight: 700;
      font-size: var(--fs-200);
      text-transform: uppercase;
    }
  }
}
