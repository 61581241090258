@use "../abstracts/mixins" as *;

.header {
  --overlap: 2rem;
  display: grid;
  grid-template-areas: "logo hamburger";
  justify-items: end;
  grid-auto-columns: auto 1fr;
  grid-auto-rows: auto 1fr auto var(--overlap);
  top: 0;
  z-index: 2;
  padding: 1rem;

  &__logo {
    grid-area: logo;
    background-color: inherit;
    background-color: white;
  }

  &__image,
  &__logo-desktop,
  &__logo-sticky {
    display: none;
  }
}

@include mq(medium) {
  .header {
    position: relative;
    padding: 0;
    padding-inline-end: 2rem;
    grid-template-areas:
      "logo secondary-navigation"
      "logo image"
      "primary-navigation primary-navigation";

    &--sticky {
      position: sticky;
      z-index: 3;
      top: 0;
      background-color: white;
      box-shadow:
        1000px 0 0 0 rgb(255 255 255),
        -1000px 0 0 0 rgb(255 255 255),
        0 0 5px 1px rgb(0 0 0 / 0.4);
      grid-template-areas: "logo primary-navigation" !important;
      isolation: isolate;

      &::before {
        z-index: -1;
      }

      .secondary-navigation {
        display: none;
      }

      .header__logo {
        padding: 0.25rem;
      }

      .header__logo-sticky {
        display: block;
      }

      .header__image,
      .header__logo-mobile,
      .header__logo-desktop {
        display: none;
      }
    }

    &__logo-mobile {
      display: none;
    }

    &__logo-desktop {
      display: block;
      height: 160px;
      width: 160px;
      padding: 1.5rem;
      padding-inline-start: 2rem;
    }

    &__image {
      display: block;
      padding-inline: 1rem;
      grid-area: image;
    }
  }
}

@include mq(big) {
  .header {
    margin-block-end: calc(var(--overlap) * -1);
    align-content: start;
    grid-template-areas:
      "logo secondary-navigation"
      "logo image"
      "logo primary-navigation"
      "logo.";
    align-items: center;

    &::after {
      content: "";
      display: block;
      background-color: white;
      position: absolute;
      inset: 0;
      grid-row: 1 / -2;
      z-index: -1;
    }

    &__logo-desktop {
      height: 230px;
      width: 100%;
    }
  }
}
