@use "../abstracts/mixins" as *;
@use "../utilities/container";

.home-content {
  @extend .container !optional;

  .text {
    margin-block: 2rem;
    font-weight: 700;
    line-height: 1.2;
  }
}

.page-content {
  width: min(978px, calc(100% - 2rem));
  margin-inline: auto;

  &.page-content--wide {
    @extend .container !optional;
  }
}

.content {
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-auto-columns: 1fr;
  grid-template-areas: "content";
  margin-block-start: 1.25rem;

  &--portlets-left {
    grid-template-areas: "portlets-left" "content";
  }

  &--portlets-right {
    grid-template-areas: "content" "portlets-right";
  }

  &--portlets-right.content--portlets-left {
    grid-template-areas: "portlets-left" "content" "portlets-right";
  }

  .main-content {
    grid-area: content;

    &--not-found {
      display: grid;
      place-content: center;
    }
  }
}

@include mq(medium) {
  .content {
    &--portlets-left {
      grid-template-areas: "portlets-left content content";
    }

    &--portlets-right {
      grid-template-areas: "content content portlets-right";
    }

    &--portlets-right.content--portlets-left {
      grid-template-areas: "portlets-left content portlets-right";
    }
  }
}
