@use "../utilities/container";

.portlets {
  align-self: start;
  display: grid;
  gap: 1rem;

  &--left {
    grid-area: portlets-left;
  }

  &--right {
    grid-area: portlets-right;
  }

  &--bottom {
    @extend .container !optional;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min(275px, 100%), 1fr));
    gap: 1rem;
    margin-block-start: 3rem;
    align-items: stretch;
  }
}
