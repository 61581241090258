.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  &__text {
    color: var(--clr-primary);
  }

  &__button {
    display: inline-flex;
    align-items: center;
    border: none;
    padding: 0.5rem;
    color: var(--clr-primary);
    background-color: transparent;

    &:disabled {
      color: var(--clr-light-grey);
      cursor: not-allowed;
    }
  }
}
