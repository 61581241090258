.partners {
  .form {
    margin-block-end: 2rem;
  }

  &__subtitle {
    color: var(--clr-dark-grey);
    border-bottom: 1px solid var(--clr-grey);
    font-size: var(--fs-400);
    margin-block-start: 2rem;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-block-start: 1rem;
    margin-block-end: 2rem;

    &:last-child {
      margin-block-end: 0;
    }
  }

  &__link {
    gap: 0.25rem;
    font-size: var(--fs-300);
    font-weight: 700;
  }
}
