.message {
  --_color: var(--color);
  --_bg-color: var(--bg-color);
  --_border-color: var(--border-color);
  color: var(--_color);
  background-color: var(--_bg-color);
  border: 1px solid var(--_border-color);
  padding: 1rem;

  &--success {
    --bg-color: #dff0db;
    --border-color: #d6e9c6;
    --color: #3c763d;
  }

  &--error {
    --bg-color: #f2dede;
    --border-color: #ebccd1;
    --color: #a94442;
  }

  &--information {
    --bg-color: #d9edf7;
    --border-color: #bce8f1;
    --color: #31708f;
  }

  &--warning {
  }
}
