@use "../abstracts/mixins" as *;

.login-form {
  display: grid;
  gap: 1rem;
  margin-block-end: 4rem;
  position: relative;

  &__element {
    width: 100%;
    display: grid;
    align-items: center;
  }

  .button[type="submit"] {
    justify-self: start;
    grid-column: 2;
  }
}

@include mq(small) {
  .login-form {
    &__element {
      grid-template-columns: minmax(150px, auto) 1fr;
    }
  }
}
