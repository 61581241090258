.form {
  --_gap: 1rem;
  display: grid;
  gap: var(--_gap);
  position: relative;

  &:first-child {
    margin-block-start: 2rem;
  }

  &__required-fields {
    position: absolute;
    right: 0;
    bottom: -2rem;
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--_gap);
  }

  &__element {
    display: grid;

    label {
      user-select: none;
    }

    select,
    textarea,
    input {
      accent-color: var(--clr-primary);
      border: 1px solid var(--clr-primary);
      background-color: var(--clr-white);
      padding: 0.5rem 0.5rem;
    }

    select {
      padding: 0.5625rem;
    }

    textarea {
      resize: vertical;
      min-height: 250px;
    }
  }

  &__fieldset {
    border: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    gap: 0 1rem;

    legend {
      padding: 0;
      margin-block-end: 0.25rem;
    }

    &--row {
      grid-template-columns: unset;
      gap: 0.25rem;
    }
  }

  &__checkbox {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;

    label {
      user-select: none;
    }

    input[type="checkbox"] {
      accent-color: var(--clr-primary);
      width: 1.25rem;
      height: 1.25rem;
      margin: 0;
    }
  }

  &__buttons {
    display: flex;
    gap: 1rem;
  }
}
