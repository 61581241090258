.search-results {
  display: grid;
  gap: 2rem;
}

.search-result {
  &__title {
    font-weight: 700;
  }

  &__description {
    color: var(--clr-grey);
  }
}
