@use "../abstracts/mixins" as *;

.hero {
  display: grid;
  grid-template-columns: auto min(970px, 100%) auto;
  grid-column: 1 / -1;
  align-items: center;
  position: relative;
  z-index: 1;
  isolation: isolate;

  &--no-banner {
    margin-block-start: 2rem;
  }

  &__linked-in {
    display: none;
  }

  &__image {
    grid-column: 1 / -1;
    grid-row: 1;
    max-width: 1280px;
    width: 100%;
    height: 100%;
    min-height: 240px;
    max-height: 350px;
    object-fit: cover;
  }

  &__title {
    position: relative;
    z-index: 0;
    grid-row: 1;
    grid-column: 2 / 3;
    width: fit-content;
    line-height: 1;
    color: transparent;
    display: grid;
    gap: 1rem;
    padding: 1rem;
  }

  &__title-text {
    background: linear-gradient(
      to right,
      var(--clr-primary),
      var(--clr-primary-light)
    );
    position: relative;
    display: inline-block;
    background-clip: text;
    width: fit-content;
    line-height: 1;
    padding: 0.5rem;

    &::before {
      content: "";
      display: block;
      background-color: var(--clr-white);
      inset: 0;
      position: absolute;
      z-index: -1;
    }
  }

  &__title--no-padding &__title-text {
    padding: 0.75rem 0 0;
  }

  &__link {
    position: absolute;
    display: inline-block;
    grid-column: 2;
    grid-row: 1;
    bottom: 2rem;
    background-color: var(--clr-primary);
    color: var(--clr-white);
    padding: 0.5rem 1rem;
    border: 1px solid var(--clr-primary);
    left: 1rem;
    transform: translateY(1rem);

    &:hover {
      background-color: var(--clr-white);
      color: var(--clr-primary);
    }
  }
}

@include mq(medium) {
  .hero {
    &__title {
      padding: 0;
    }

    &__linked-in {
      display: block;
      position: fixed;
      top: 300px;
      right: calc((100% - min(1280px, 100%)) / 2);
      padding: 0.125rem;
      background-color: white;
      transform: translateY(4rem);
      box-shadow: 0 0 5px 1px rgb(0 0 0 / 0.4);
      border-radius: 2px;
      z-index: 1;
    }

    &__link {
      left: 50%;
      transform: translateX(-200%);
    }

    &__title-text {
      padding: 0.75rem 2rem 0.5rem;

      &:nth-child(2) {
        margin-inline-start: 4rem;
      }
    }
  }
}
