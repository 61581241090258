@use "../abstracts/mixins" as *;
@include mq(medium, max) {
  .hamburger {
    grid-area: hamburger;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    width: 3rem;
    height: 3rem;

    span {
      height: 0.25rem;
      width: 100%;
      border-radius: 20px;
      background: var(--clr-primary);
    }

    &::before,
    &::after {
      content: "";
      height: 0.25rem;
      background: var(--clr-primary);
      width: 100%;
      position: relative;
      transition: all ease 0.2s;
      border-radius: 20px;
    }

    &::before {
      left: 0;
    }

    &::after {
      top: 0;
      left: 0;
    }

    &[aria-expanded="true"] + .primary-navigation {
      z-index: -1;
      transform: translateY(0px);
      position: absolute;
      display: block;
      background-color: white;
      padding: 0;
      left: 0;
      right: 0;
      margin-block-start: 2.125rem;
    }
  }

  .hamburger[aria-expanded="true"] {
    &::before {
      top: 0.8125rem;
      transform: rotate(45deg);
    }
    &::after {
      transform: rotate(-45deg);
      top: -0.75rem;
    }
    span {
      opacity: 0;
    }
  }
}
@include mq(medium) {
  .hamburger {
    display: none;
  }
}
