.sitemap {
  margin-block-start: 1rem;

  ul {
    margin: 0;
    padding-inline-start: 1.5rem;
  }

  a {
    font-size: var(--fs-400);
  }
}
