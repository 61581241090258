@use "../abstracts/mixins" as *;

.article-teaser {
  margin-block-end: var(--spacer-md);

  &__image {
    width: 300px;
  }

  &__title {
    font-weight: 700;
    color: var(--clr-primary);
    margin-block-end: 0.75rem;
  }

  &__text {
    font-size: 1rem;

    & + & {
      margin-block-start: 0.5em;
    }
  }
}

@include mq(medium) {
  .article-teaser {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }
}
