.events {
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-block: 1rem;
  }
}

.event {
  border-top: 1px solid var(--clr-light-grey);
  font-size: var(--fs-300);
  padding-block: 0.5rem;

  &__date,
  &__title,
  &__location {
    margin-block: 0.5rem;
  }

  &__title {
    font-weight: 700;
    margin-block: 0.5rem;
    color: var(--clr-primary);
    font-size: var(--fs-400);
  }

  &__link {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
  }
}
