.button {
  background-color: var(--clr-white);
  color: var(--clr-primary);
  padding: 0.5rem 2.5rem;
  line-height: 1;
  border-radius: 8px;
  font-size: var(--fs-300);
  font-weight: 700;
  border: 1px solid var(--clr-primary);
  outline-offset: 4px;
  display: inline-block;

  &--primary {
    background-color: var(--clr-primary);
    color: var(--clr-white);

    &:hover {
      color: var(--clr-white);
    }
  }

  &--secondary {
    background-color: var(--clr-secondary);
    color: var(--clr-white);
    border-color: var(--clr-secondary);

    &:hover {
      color: var(--clr-white);
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: var(--clr-dark-grey);
  }
}
