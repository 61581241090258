/* purgecss start ignore */
@use "../abstracts/mixins" as *;

$regions: "aachen", "bergstaedte", "duesseldorf", "koelnbonn", "metropoleruhr",
  "muensterland", "niederrhein", "ostwestfalenlippe", "suedwestfalen";

.nrw-map {
  --_padding: 3rem;
  display: grid;
  position: relative;
  grid-template-rows: var(--_padding) 1fr var(--_padding);
  grid-template-columns: 1fr min(410px, 100%);
  isolation: isolate;
  margin-block: 3rem;

  &::after {
    content: "";
    background-color: var(--clr-light-grey);
    position: absolute;
    inset: 0;
    grid-row: 2 / span 1;
    grid-column: 1 / -1;
    z-index: -1;
  }

  &__title {
    grid-column: 1 / -1;
    grid-row: 1;
    font-size: var(--fs-400);
    margin: 0;
  }

  &__links {
    grid-row: 2 / span 1;
    grid-column: 1 / -1;

  }

  &__list {
    list-style: none;

    a {
      line-height: 30px;
      font-size: 1.375rem;
      font-weight: 300;
      color: rgb(100, 100, 100);
    }
  }

  &__map {
    display: none;
  }
}

@include mq(medium) {
  .nrw-map {
    &__map {
      display: grid;
      grid-row: 1 / -1;
      grid-column: 2;

      // purgecess ignore
      &>* {
        grid-column: 1;
        grid-row: 1;
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    &__links {
      grid-column: 1;
    }

    &__dot {
      display: none;
    }

    @each $region in $regions {
      &__links:has([data-region="#{$region}"]:hover)+&__map &__dot[data-region="#{$region}"] {
        display: initial;
        filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.4));
      }
    }
  }
}

/* purgecss end ignore */