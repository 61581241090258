@use "../components/button";
@use "../abstracts/mixins" as *;
/* purgecss start ignore */
.richtext {
  p {
    hyphens: auto;
  }

  p + p {
    margin-block-start: 1rem;
  }

  .btn {
    margin-block-start: 0.25rem;
    margin-block-end: 1rem;
    @extend .button !optional;
  }

  .btn:only-child {
    margin-block-start: 0rem;
  }

  .btn-primary {
    @extend .button--primary !optional;
  }

  .image-richtext {
    display: table;

    img {
      object-fit: contain;
    }

    &.image-right {
      float: right;
      margin-inline-start: 1rem;
      margin-block-end: 1rem;
    }

    &.image-left {
      float: left;
      margin-inline-end: 1rem;
      margin-block-end: 1rem;
    }

    figcaption.image-caption {
      display: table-caption;
      caption-side: bottom;
      font-size: var(--fs-200);
      color: var(--clr-grey);
      margin-top: 0.25rem;
    }

    &.picture-variant-small {
      img {
        width: 100%;
        height: auto;
        max-height: 400px;
      }
    }

    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .image-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;

    .image-right {
      float: unset;
      margin: 0;
      object-fit: contain;
      width: 100%;
      height: auto;
    }

    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .image-container-big {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;

    .image-right {
      float: unset;
      margin: 0;
      object-fit: contain;
      width: 100%;
      height: auto;
    }

    &::after {
      content: "";
      display: table;
      clear: both;
    }
  }

  .shoutout-divider,
  .gear-divider,
  .pin-divider,
  .clock-divider,
  .rocket-divider {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    color: var(--clr-light-grey);
    margin-block: 1.25rem;

    &::before,
    &::after {
      content: "";
      flex: 1;
      height: 1px;
      background-color: var(--clr-light-grey);
    }
  }

  .big-shoutout-divider,
  .big-gear-divider,
  .big-pin-divider,
  .big-clock-divider,
  .big-rocket-divider {
    margin-inline: auto;
    width: fit-content;
    padding: 2rem;
    border-radius: 1000px;
    width: 7rem;
    height: 7rem;
    color: var(--clr-primary);
    margin-block: 1.25rem;
    border: 2px solid var(--clr-primary-light);

    svg {
      width: 100%;
      height: 100%;

      * {
        stroke-width: 3;
      }
    }
  }

  .icon-shoutout,
  .icon-pin,
  .icon-gear,
  .icon-clock,
  .icon-rocket {
    display: inline-block;
    padding: 10px;
    border-radius: 1000px;
    border: 2px solid var(--clr-primary-light);
    font-size: 1.125rem;
    line-height: 9px;
    margin-block-end: 0.25rem;
    margin-inline-end: 0.5rem;

    &::before {
      content: "";
      display: inline-block;
      width: 1.125rem;
      height: 1.125rem;
      background-size: 1.125rem;
      background-repeat: no-repeat;
      background-position: center;
      line-height: 1.125rem !important;
      font-size: 1.125rem;
    }
  }

  .icon-shoutout::before {
    background-image: url("/shoutout.svg");
  }

  .icon-rocket::before {
    background-image: url("/rocket.svg");
  }

  .icon-pin::before {
    background-image: url("/pin.svg");
  }

  .icon-gear::before {
    background-image: url("/gear.svg");
  }

  .icon-clock::before {
    background-image: url("/clock.svg");
  }

  blockquote {
    color: var(--clr-primary);
    font-weight: 700;
    padding: 3rem;
    text-align: center;
  }
}

@include mq(small) {
  .richtext {
    .image-container {
      max-width: 350px;
      float: right;
      margin-block-start: 0;
      margin-inline-start: 1rem;
      margin-block-end: 1rem;
    }
  }
}

/* purgecss end ignore */
