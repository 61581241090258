.colormode-toggle {
  display: inline-flex;
  align-items: center;
  gap: 0.175rem;

  input {
    display: inline-block;
    height: 0;
    width: 0;
    margin: 0;
  }

  &__slider {
    display: inline-block;
    width: 2rem;
    height: 1rem;
    position: relative;
    cursor: pointer;

    &::before {
      position: absolute;
      content: "";
      background-color: var(--clr-grey);
      height: 8px;
      left: 0.25rem;
      right: 0.25rem;
      top: 50%;
      border-radius: 10px;
      transform: translateY(-50%);
    }

    &::after {
      content: "";
      position: absolute;
      display: block;
      left: 0.25rem;
      width: 0.375rem;
      height: 0.375rem;
      background-color: var(--clr-white);
      box-shadow:
        0 0 0 5px var(--clr-primary),
        0 0 0 7px var(--clr-white);
      top: 50%;
      transform: translateY(-50%);
      border-radius: 1rem;
      transition: translate 200ms ease-in-out;
    }
  }

  input:checked + &__slider::after {
    translate: 1rem 0;
  }
}
