#vinegar-bottle {
  --vinegar_main: var(--clr-primary);
  --vinegar_darker: var(--clr-primary-dark);
  --vinegar_lighter: var(--clr-primary);
  z-index: 2;
}

#vinegar-bottle
  #vinegar-long
  .vinegar-option
  .vinegar-option_box
  input:checked
  + .slider,
#vinegar-bottle #vinegar-save_all button {
  background-color: var(--vinegar_main, #005498);
  color: var(--vinear_lighter, #f1f1f1);
}
#vinegar-bottle #vinegar-save_options button,
#vinegar-bottle
  #vinegar-short
  .vinegar-buttons
  .vinegar-button#vinegar-show_more
  button {
  border: 1px solid var(--vinegar_darker, #28568c);
}
#vinegar-bottle #vinegar-save_options button,
#vinegar-bottle .vinegar-title,
#vinegar-bottle a {
  color: var(--vinegar_darker, #28568c);
  text-decoration: underline;
}

vine-gar:not([type]) {
  display: none;
}

vine-gar[type="iframe"] {
  align-items: center;
  border: 1px solid #000;
  display: flex;
  height: 500px;
  justify-content: center;
  margin: 0 5%;
  text-align: center;
}

vine-gar[type="iframe"]:empty:after {
  content: "This feature requires JS. If you would like to use it please be sure to enable the latter.";
  padding: 0 20%;
}

vine-gar[purpose="youtube"]:empty:after {
  content: "Um dieses YouTube Video anzusehen aktivieren Sie bitte YouTube in den Cookie-Einstellungen.";
  padding: 0 2rem;
}

vine-gar iframe[src] + span {
  display: none;
}
vine-gar iframe {
  height: 100%;
  width: 100%;
}
vinegar[type="map"] {
  background-image: url(/vinegar/staticmap.png);
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 400px;
  min-height: 290px;
  position: relative;
  width: 100%;
}
.vinegar_change,
vinegar[type] {
  cursor: pointer;
}
vine-gar[type] + iframe[src] {
  cursor: unset;
}
vine-gar span {
  padding: 10%;
}
#vinegar-bottle {
  background-color: #fff;
  color: #666;
  font-size: 12px;
  left: 0;
  position: fixed;
}
#vinegar-bottle.hidden,
#vinegar-bottle.long #vinegar-short,
#vinegar-bottle.short #vinegar-long {
  display: none;
}
#vinegar-bottle #vinegar-short {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
#vinegar-bottle #vinegar-short .vinegar-text {
  flex: 1 1 60%;
  max-width: 80%;
  min-width: 60%;
  order: 1;
}
#vinegar-bottle #vinegar-save_options button {
  background-color: #fff;
}
#vinegar-bottle
  #vinegar-short
  .vinegar-buttons
  .vinegar-button#vinegar-show_more
  button {
  background-color: #f1f1f1;
  color: #919191;
}
#vinegar-bottle .vinegar-title {
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}
#vinegar-bottle .vinegar-buttons button {
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  min-width: 220px;
  padding: 16px;
}
#vinegar-bottle .vinegar-buttons#vinegar-options_buttons button {
  padding: 12px;
}
#vinegar-bottle #vinegar-short .vinegar-buttons .vinegar-button button {
  margin-bottom: 8px;
}
#vinegar-bottle
  #vinegar-short
  .vinegar-buttons
  .vinegar-button#vinegar-show_more
  button {
  font-size: 90%;
}
#vinegar-bottle #vinegar-long .vinegar-options_title {
  border-bottom: 1px solid #a1a1a1;
  font-size: 24px;
  margin-top: 30px;
  padding-bottom: 20px;
}
#vinegar-bottle #vinegar-long #vinegar-back {
  align-items: center;
  background-color: #fff;
  border: 0;
  cursor: pointer;
  display: flex;
  font-size: 1em;
  margin: 10px 10px 0 0;
  padding: 4px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 30px;
}
#vinegar-bottle #vinegar-long #vinegar-back svg {
  height: 20px;
}
#vinegar-bottle #vinegar-options_upper {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
}
#vinegar-bottle #vinegar-options_upper #vinegar-spacer {
  flex-grow: 1;
  flex-shrink: 1;
  min-width: 40px;
  order: 2;
}
#vinegar-bottle #vinegar-options_upper .vinegar-options_title {
  font-size: 1.25em;
  font-weight: 500;
  padding-bottom: 10px;
}
#vinegar-bottle #vinegar-options_upper #vinegar-options_buttons {
  order: 3;
}
#vinegar-bottle #vinegar-long .vinegar-option {
  align-items: center;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  display: flex;
  flex-flow: row wrap;
  margin: 8px 0;
  padding: 20px;
}
#vinegar-bottle #vinegar-long .vinegar-option .vinegar-option_box {
  margin-left: 10px;
}
#vinegar-bottle #vinegar-long .vinegar-option:first-child {
  margin-top: 0;
}
#vinegar-bottle #vinegar-long .vinegar-option:last-child {
  margin-bottom: 0;
}
#vinegar-bottle #vinegar-long #vinegar-no_options,
#vinegar-bottle #vinegar-long #vinegar-options {
  margin: 8px 0;
}
#vinegar-bottle
  #vinegar-long
  .vinegar-option
  .vinegar-option_description_title {
  font-weight: 600;
}
#vinegar-bottle #vinegar-long .vinegar-option .vinegar-option_box {
  display: inline-block;
  flex: 0 0 52px;
  height: 30px;
  order: 2;
  position: relative;
  width: 52px;
}
#vinegar-bottle #vinegar-long .vinegar-option .vinegar-option_box input {
  height: 0;
  opacity: 0;
  width: 0;
}
#vinegar-bottle #vinegar-long .vinegar-option .vinegar-option_box .slider {
  background-color: #ccc;
  border-radius: 30px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
  width: auto;
}
#vinegar-bottle
  #vinegar-long
  .vinegar-option
  .vinegar-option_box
  input[type="checkbox"]:disabled
  + .slider {
  opacity: 0.4;
}
#vinegar-bottle
  #vinegar-long
  .vinegar-option
  .vinegar-option_box
  .slider:before {
  background-color: #fff;
  border-radius: 50%;
  bottom: 4px;
  content: "";
  height: 22px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 22px;
}
#vinegar-bottle
  #vinegar-long
  .vinegar-option
  .vinegar-option_box
  input:checked
  + .slider:before {
  transform: translateX(22px);
}
#vinegar-bottle #vinegar-long .vinegar-option .vinegar-option_description {
  flex: 1 1 60%;
  order: 1;
}
#vinegar-bottle .vinegar-buttons-wrapper {
  margin-bottom: 20px;
  margin-top: 50px;
  text-align: center;
}
#vinegar-bottle #vinegar-save_options button {
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  #vinegar-bottle #vinegar-short .vinegar-buttons {
    display: flex;
    flex-direction: column;
  }
  #vinegar-bottle #vinegar-short .vinegar-buttons:not(:first-child) {
    margin-left: 10px;
  }
  #vinegar-bottle #vinegar-short .vinegar-text {
    max-width: inherit;
    padding-right: 0;
  }
  #vinegar-long .vinegar-buttons {
    flex-direction: column;
    float: none;
  }
  #vinegar-long .vinegar-button button {
    width: 100%;
  }
  #vinegar-long #vinegar-save_options {
    order: 2;
  }
  #vinegar-long #vinegar-save_all {
    order: 1;
    padding-bottom: 10px;
  }
}
@media screen and (min-width: 768px) {
  #vinegar-bottle {
    font-size: 16px;
  }
  #vinegar-bottle .vinegar-title {
    font-size: 24px;
    line-height: normal;
  }
  #vinegar-bottle .vinegar-buttons button {
    font-size: 16px;
  }
  #vinegar-bottle .vinegar-buttons-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    margin-top: 50px;
  }
  #vinegar-bottle #vinegar-save_options button {
    margin-bottom: 0;
    margin-right: 10px;
  }
}
#vinegar-bottle #vinegar-short .vinegar-buttons {
  order: 2;
}
#vinegar-long .vinegar-button div {
  padding-left: 10px;
}
#vinegar-long .vinegar-buttons {
  display: flex;
  justify-content: space-around;
}
#vinegar-bottle.footer {
  bottom: 0;
  box-shadow: 0 -1px 10px #888;
  margin-left: 0;
  max-height: 400px;
  max-width: 100%;
  overflow-y: auto;
  padding: 20px 5%;
}
@media screen and (max-width: 900px) and (orientation: landscape) {
  #vinegar-bottle.footer {
    max-height: 90%;
  }
}
#vinegar-bottle.footer #vinegar-short .vinegar-buttons {
  flex: 0 0 200px;
  margin: 20px 0 20px 20px;
}
@media (min-width: 768px) {
  #vinegar-bottle.footer #vinegar-long .vinegar-buttons {
    float: right;
    padding-bottom: 15px;
  }
  #vinegar-bottle.footer #vinegar-save_all button {
    border-radius: 0 8px 8px 0;
  }
  #vinegar-bottle.footer #vinegar-save_options button {
    border-radius: 8px 0 0 8px;
  }
  #vinegar-bottle.footer #vinegar-long .vinegar-buttons {
    margin-bottom: 24px;
  }
}
#vinegar-bottle.footer .vinegar-buttons button {
  border-radius: 8px;
  padding: 9px;
}
#vinegar-bottle.modal {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  height: 100%;
  justify-content: center;
  max-width: 100%;
  top: 0;
  width: 100%;
}
#vinegar-bottle.modal > div {
  -ms-overflow-style: none;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 -1px 10px #888;
  margin: 10px;
  max-width: 500px;
  padding: 40px 2% 20px;
  position: relative;
  scrollbar-width: none;
  width: 100%;
}
#vinegar-bottle.modal > div::-webkit-scrollbar {
  display: none;
}
#vinegar-bottle.modal #vinegar-long {
  max-height: 80%;
  overflow-y: scroll;
}
@media (max-width: 767px) {
  #vinegar-bottle.modal #vinegar-short .vinegar-buttons {
    align-items: center;
  }
  #vinegar-bottle.modal > div {
    padding: 40px 4% 15px;
  }
}
#vinegar-bottle.modal #vinegar-short .vinegar-buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  padding-top: 10px;
  width: 100%;
}
#vinegar-long.modal .vinegar-buttons {
  justify-content: space-around;
  margin-bottom: 15px;
  margin-top: 15px;
}
