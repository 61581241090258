@use "../abstracts/mixins" as *;
/* purgecss start ignore */

.menu {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-secondary {
    display: none;
  }

  &__sub-list {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-block: 0.5rem;
    background-color: var(--clr-white);
    width: max-content;
  }

  &__trigger,
  &__link {
    all: unset;
    box-sizing: border-box;
    width: 100%;
    font-weight: 700;
    cursor: pointer;
    padding-inline: 0.5rem;
    padding-block: 1rem;
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;

    &:hover {
      color: var(--clr-secondary);
      text-decoration: underline;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 2px solid var(--clr-primary);
    }
  }
}

@include mq(medium, max) {
  .menu {
    display: grid;

    &__list {
      display: flex;
      flex-direction: column;
    }

    &__list-secondary {
      display: unset;
      margin: 0;
      padding: 0;
    }

    &__list-secondary &__link {
      background-color: var(--clr-grey);
    }

    &__sub-list {
      background-color: var(--clr-grey);
      padding: 0;
      margin: 0;
      width: 100%;
    }

    &__item {
      padding: 1rem;
      border-bottom: 1px solid var(--clr-white);
    }

    &__trigger,
    &__link {
      display: flex;
      justify-content: space-between;
      background-color: var(--clr-primary);
      color: var(--clr-white);
      font-weight: 700;
      border-bottom: 1px solid var(--clr-white);
      padding: 1rem;
    }

    &__sub-link {
      font-weight: 700;
      color: white;
      display: block;
    }
  }
}

@include mq(medium) {
  .menu {
    &__list {
      display: flex;
      align-items: center;
      margin: 0;
      padding-inline: 0.5rem;
    }

    &__sub-list {
      outline: 1px solid var(--clr-secondary);
    }

    &__trigger,
    &__link {
      color: var(--clr-primary);
      font-size: 1.125rem;
    }

    &__sub-link {
      color: var(--clr-grey);
      padding-inline: 1rem;
      padding-block: 0.5rem;
    }

    // purgecss ignore
    &__content[data-state="closed"] {
      display: none;
    }

    // purgecss ignore
    &__content[data-state="open"] {
      display: block;
      position: absolute;
      font-size: 1.0625rem;
    }

    // purgecss ignore
    &__trigger[data-state="open"] > &__caret-down {
      transform: rotate(-180deg);
    }
  }

  @media not (prefers-reduced-motion) {
    .menu {
      // purgecss ignore
      &__content[data-state="open"] {
        animation: slideIn 200ms;
      }

      &__caret-down {
        transition: transform 200ms ease-in-out;
      }
    }

    @keyframes slideIn {
      0% {
        opacity: 0;
        translate: 0 1rem;
      }

      100% {
        opacity: 1;
        translate: 0 0;
      }
    }
  }
}

/* purgecss end ignore */
