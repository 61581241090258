// purgecss start ignore
@use "sass:list";
@use "../abstracts" as *;

:root {
  --spacer-lg: 4rem;
  --spacer-md: 2rem;
  --spacer-sm: 1rem;

  @include mq(medium) {
    --spacer-lg: 5rem;
  }

  @each $color, $value in $colors {
    --clr-#{$color}: #{$value};
  }

  @each $size, $property in $type-scale {
    --fs-#{$size}: #{fluid(list.nth($property, 1), list.nth($property, 2))};
  }

  @media (prefers-contrast: more) {
    @each $color, $value in $high-contrast-colors {
      --clr-#{$color}: #{$value};
    }
  }
}

html.high-contrast {
  @each $color, $value in $high-contrast-colors {
    --clr-#{$color}: #{$value};
  }
}
//purgecess end ignore
