.file-download {
  background-color: transparent;
  cursor: pointer;
  border: 1px solid black;
  padding: 0.5rem;
  position: relative;

  &__loading-overlay {
    display: grid;
    position: absolute;
    inset: 0;
    background-color: rgb(255 255 255 / 0.9);
    place-content: center;
    place-items: center;

    img {
      object-fit: contain;
      width: 100%;
      height: auto;
    }

    p {
      color: var(--clr-dark-grey);
    }
  }
}
