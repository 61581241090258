.mitpfeil {
  &:after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' data-name='Ebene 1' viewBox='0 0 23.79 12.86' height='12'%3E%3Cpath d='M0 0h23.79v12.86H0z' style='fill: rgb(2, 77, 161);'%3E%3C/path%3E%3Cpath d='M22.57 6.43 14 1.48v9.9l8.57-4.95z' style='fill: rgb(255, 255, 255);'%3E%3C/path%3E%3C/svg%3E");
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    background-size: cover;
    height: 12px;
    width: 22px;
    display: inline-block;
    margin-inline-start: 4px;
  }
}
