.news-listing {
  list-style: none;
  margin: 0;
  padding: 0;

  &__date {
    margin: 0;
  }

  &__item {
    padding-block: 0.5rem;
    border-top: 1px solid var(--clr-light-grey);
  }

  &__link {
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    gap: 0.5rem;
  }

  &__description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }
}
