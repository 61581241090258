.calendar {
  background-color: var(--clr-secondary);

  &__element {
    display: grid;
    grid-template-areas: "view" "navigation";
    width: 100%;

    .react-calendar__navigation__arrow,
    .react-calendar__navigation__label,
    .react-calendar__tile {
      border: none;
    }

    .react-calendar__tile--now,
    .react-calendar__tile--now:enabled:focus,
    .react-calendar__tile--now:enabled:hover {
      background-color: var(--clr-primary-light);
      background: var(--clr-primary-light);
      color: var(--clr-white) !important;
    }

    .react-calendar__month-view__days__day--weekend {
      color: var(--clr-primary);
    }

    .react-calendar__tile--active,
    .react-calendar__tile--active:enabled:focus,
    .react-calendar__tile--active:enabled:hover {
      background-color: var(--clr-primary);
      background: var(--clr-primary);
      color: var(--clr-white) !important;
    }

    .react-calendar__navigation {
      grid-area: navigation;
      margin: 0;
      margin-block-start: 1rem;
    }

    .react-calendar__viewContainer {
      grid-area: view;
    }

    .has-events {
      font-weight: 900;
      color: var(--clr-primary);
    }
  }

  &__title {
    padding: 1rem;
    margin: 0;
    color: var(--clr-white);
    font-size: var(--fs-400);
  }

  &__no-events,
  &__event {
    margin-block-end: 1rem;
  }

  &__events {
    padding: 1rem;
    color: var(--clr-white);
    font-size: var(--fs-300);
  }

  &__event {
    color: var(--clr-white);

    &:hover {
      color: var(--clr-white);
    }
  }

  &__event-title {
    font-weight: 700;
  }

  &__button {
    margin-block-start: 1rem;
    background-color: var(--clr-white);
    color: var(--clr-secondary);
    border-color: var(--clr-secondary);

    &:hover {
      color: var(--clr-primary);
      text-decoration: underline;
    }
  }
}
