body {
  font-size: var(--fs-400);
  color: var(--clr-grey);
}

/* purgecss start ignore */
h1,
h2,
h3,
h4 {
  font-weight: 700;
  font-stretch: 100%;
  line-height: 1.1;
  text-wrap: balance;
}

h1 {
  font-weight: 700;
}

// This is setting the font-sizes
// based on the ones in the abstract
// folder, go there to make updates

h1,
.h1 {
  font-size: var(--fs-800);
}

h2,
.h2 {
  font-size: var(--fs-700);
  margin-block-start: var(--spacer-md);
  margin-block-end: var(--spacer-sm);
  color: var(--clr-primary);
}

h3,
.h3 {
  font-size: var(--fs-600);
  color: var(--clr-primary);
  margin-block: var(--spacer-sm);
}

h4,
.h4 {
  font-size: var(--fs-500);
  color: var(--clr-primary);
  margin-block: var(--spacer-sm);
}

p:not([class]) {
  margin-block: 1rem;
}

small,
.text-small {
  font-size: var(--fs-300);
}

strong {
  font-weight: 700;
}

a {
  color: var(--clr-primary);
  text-decoration: none;

  &:hover {
    color: var(--clr-primary-dark);
    text-decoration: underline;
  }
}

button {
  cursor: pointer;
}

ul {
  padding: 0;
  padding-inline-start: 1.25rem;
}
/* purgecss end ignore */
