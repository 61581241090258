@use "../abstracts/mixins" as *;

.breadcrumbs {
  display: none;
}

@include mq(medium) {
  .breadcrumbs {
    --_gap: 0.5rem;
    display: flex;
    margin-block-end: 1.25rem;
    align-items: center;
    gap: var(--_gap);
    font-size: 0.875rem;
    color: var(--clr-grey);
    padding-block: 1rem;

    &__list {
      display: inline-flex;
      align-items: center;
      gap: var(--_gap);
      padding: 0;
      margin: 0;
    }

    &__list-item {
      display: inline-flex;
      align-items: center;
      gap: var(--_gap);

      &:not(:last-child)::after {
        content: "/";
      }
    }

    &__link {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
