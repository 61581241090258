@use "../abstracts/mixins" as *;

.related-items {
  display: grid;
  gap: 3rem 2rem;
  margin-block-end: 2rem;
}

@include mq(small) {
  .related-items {
    grid-template-columns: repeat(2, 1fr);
  }
}

.related-item {
  &__figure {
    display: grid;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    /* purgecss ignore */
    figcaption {
      position: absolute;
      color: var(--clr-light-grey);
      font-size: var(--fs-200);
      inset: auto auto 0.25rem 0.5rem;
    }
  }

  &__story {
    display: grid;
    gap: 1rem;
    align-items: start;
    grid-template-rows: 225px auto;

    &--without-image {
      grid-template-rows: unset;
    }

    &:hover {
      text-decoration: none;
      color: var(--clr-primary);
    }
  }

  &__description {
    align-items: center;
    color: var(--clr-grey);
  }

  &__title {
    font-size: var(--fs-400);
    text-wrap: unset;
    margin-block-start: 0;
    margin-block-end: 1rem;
    font-weight: 400;
    color: var(--clr-grey);
  }

  &--with-description &__title {
    color: var(--clr-primary);
    font-weight: 700;
  }

  &__image {
    width: 100%;
    height: 100%;
    // object-fit: cover;
    object-fit: contain;
  }

  &__story:hover &__image {
    filter: opacity(80%);
  }
}
