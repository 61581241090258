@use "../abstracts/mixins" as *;
// html {
//   color-scheme: light dark;
// }

html {
  background-color: var(--clr-light-grey);
}

body {
  width: min(100%, 1280px);
  min-height: calc(100vh + 0.125rem);
  min-height: calc(100dvh + 0.125rem);
  margin-inline: auto;
  display: grid;
  grid-template-rows: auto 1fr auto;
  background-color: white;

  ::selection {
    background: var(--clr-primary);
    /* WebKit/Blink Browsers */
    color: white;
  }

  ::-moz-selection {
    background: var(--clr-primary);
    /* Gecko Browsers */
    background-color: var(--clr-primary);
    color: white;
  }
}

@include mq(big) {
  body {
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      width: 0.5rem;
      height: 700px;
      z-index: 3;
      background: linear-gradient(
        to bottom,
        var(--clr-primary),
        var(--clr-primary-lighter)
      );
    }
  }
}
