.navigation {
  &__list {
    list-style: none;
    padding: 0;
  }

  &__link {
    color: var(--clr-dark-grey);

    &:focus-visible,
    &:hover {
      color: var(--clr-primary);
    }

    &--current {
      font-weight: 700;
      color: var(--clr-primary);
    }
  }
}
