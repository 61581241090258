@use "../components/button";

.portlet {
  padding: 1rem;
  font-size: var(--fs-300);

  &__title {
    font-weight: 700;
    font-size: var(--fs-400);
    margin-block-end: 0.5rem;
  }

  // purgecss ignore
  .richtext p {
    margin-block: 0;
    margin-block-end: 1rem;
  }

  // purgecss ignore
  .richtext p > a:only-child {
    @extend .button !optional;
  }
}
