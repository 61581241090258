.login {
  width: min(978px, calc(100% - 2rem));
  margin-inline: auto;

  &__info,
  &__error {
    padding: 1.25rem;
    margin-block: 1rem;
    font-size: var(--fs-300);
  }

  &__info {
    background-color: #d9edf7;
    border: 1px solid #bce8f1;
    color: #31708f;
  }

  &__error {
    background-color: #f2dede;
    border: 1px solid #ebccd1;
    color: #a94442;
  }
}
