/* purgecss start ignore */
.accordion {
  display: grid;
  gap: 1rem;

  &__trigger {
    color: var(--clr-primary);
    text-align: left;
    width: 100%;
    background-color: var(--clr-white);
    border: 1px solid var(--clr-primary);
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: var(--fs-400);

    &[data-state="open"] {
      svg {
        rotate: -180deg;
      }
    }
  }

  &__content {
    overflow: hidden;

    &[data-state="closed"] {
      height: 0;
    }

    &[data-state="closed"] > .richtext {
      display: none;
    }

    &[data-state="open"] {
      padding: 1rem;
      border: 1px solid var(--clr-primary);
      border-top: none;
      height: auto;
    }
  }
}

@media not (prefers-reduced-motion) {
  .accordion {
    &__trigger svg {
      transition: rotate 200ms ease-out;
    }
  }
}

/* purgecss end ignore */
